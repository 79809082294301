import '../css/app-printphoto.css';
import '../css/app-printphoto-photo-stream.css';

import '../../js/external/imgComparisonSlider';
import '../../js/external/baguettebox';

import alertify from '../../js/external/alertifyjs'

import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

import {swiffyslider} from './../../../node_modules/swiffy-slider/dist/js/swiffy-slider.esm.min.js'
import './../../../node_modules/swiffy-slider/dist/css/swiffy-slider.min.css';
import PrintPhotoPhotoStreamForm from "./PrintPhotoPhotoStreamForm";

window.alertify = alertify;
window.tippy = tippy;

document.addEventListener("DOMContentLoaded", () => {
    tippy('[data-tippy-content]', {
        allowHTML: true
    });
});

if (document.getElementById('photoStreamForm')) {
    const printPhotoPhotoStreamForm = new PrintPhotoPhotoStreamForm();
    printPhotoPhotoStreamForm.init();
}