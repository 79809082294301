import langCode from "../../js/translate/lang-code";

class PrintPhotoPhotoStreamForm {
    constructor() {
        this.form = document.getElementById('photoStreamForm');
        if (!this.form) {
            return;
        }

        this.wrapper = document.getElementById('wrapper');
        this.rightWrapper = document.getElementById('rightWrapper');
        this.rightSide = document.getElementById('rightSide');
        this.rightSideNewFolder = document.getElementById('rightSideNewFolder');
        this.headerButtons = document.getElementById('headerButtons');
        this.pointIdControl = document.getElementById('photoStreamFormPointId');
        this.userIdControl = document.getElementById('photoStreamFormUserId');
        this.lastPhotoIdControl = document.getElementById('photoStreamFormLastPhotoId');
        this.photoTypeView = document.getElementById('photoTypeView');
        this.buttonsChangeViewType = document.querySelectorAll('.buttonChangeViewType');
        this.buttonSessionBlockToogle = document.getElementById('buttonSessionBlockToggle');
        this.photoSessionBlockToggle = document.getElementById('photoBlockSessionToggle');
        this.buttonGoToProcess = document.getElementById('buttonGoToProcess');
        this.wrapperUl = null;

        this.iconsSVG = {
            folder: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51 47" data-act="folder">' +
                '<path d="M49,11H48V8a2.91,2.91,0,0,0-3-3H18V3a2.91,2.91,0,0,0-3-3H3A2.91,2.91,0,0,0,0,3V38a2.91,2.91,0,0,0,3,3H46a2.9,2.9,0,0,0,3-2.92C49.34,35.55,52,16,52,14A2.91,2.91,0,0,0,49,11ZM3,2H15a.91.91,0,0,1,1,1V7H45a.91.91,0,0,1,1,1v3H6a2.91,2.91,0,0,0-3,2.93l-1,8V3A.91.91,0,0,1,3,2ZM47,37.87V38a.91.91,0,0,1-1,1H3a.92.92,0,0,1-1-.94L5,14.12V14a.91.91,0,0,1,1-1H49a.91.91,0,0,1,1,1C50,15.58,47.84,31.76,47,37.87Z"></path>' +
                '</svg>',
            opt: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49 49" data-act="options" data-opt="1">' +
                '<path d="M49,29V20H43.9a19.82,19.82,0,0,0-2.5-6L45,10.36,38.64,4,35,7.6a19.82,19.82,0,0,0-6-2.5V0H20V5.1a19.82,19.82,0,0,0-6,2.5L10.36,4,4,10.36,7.6,14a19.82,19.82,0,0,0-2.5,6H0v9H5.1a19.82,19.82,0,0,0,2.5,6L4,38.64,10.36,45,14,41.4a19.82,19.82,0,0,0,6,2.5V49h9V43.9a19.82,19.82,0,0,0,6-2.5L38.64,45,45,38.64,41.4,35a19.82,19.82,0,0,0,2.5-6Zm-7.53,8.93.71.71-3.54,3.54L36.7,40.24h0l-1.48-1.48A17.82,17.82,0,0,1,27,42.18V47H22V42.18a17.82,17.82,0,0,1-8.22-3.42L12.3,40.24h0l-1.94,1.94L6.82,38.64l.71-.71h0l2.7-2.7A17.7,17.7,0,0,1,6.82,27H2V22H6.82a17.7,17.7,0,0,1,3.41-8.23L8.76,12.3,7.53,11.06l-.71-.7,3.54-3.54.7.71,2.72,2.71A17.82,17.82,0,0,1,22,6.82V2h5V6.82a17.82,17.82,0,0,1,8.22,3.42l2.72-2.71.7-.71,3.54,3.54-.71.7L40.24,12.3l-1.47,1.47A17.7,17.7,0,0,1,42.18,22H47v5H42.18a17.7,17.7,0,0,1-3.41,8.23Z"></path>' +
                '</svg>',
            move: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51 47" data-act="options" data-opt="2">' +
                '<path d="M47,20H31a3.89,3.89,0,0,1-4-4V4a3.89,3.89,0,0,1,4-4H47a3.89,3.89,0,0,1,4,4V16A3.89,3.89,0,0,1,47,20ZM31,2a1.88,1.88,0,0,0-2,2V16a1.88,1.88,0,0,0,2,2H47a1.88,1.88,0,0,0,2-2V4a1.88,1.88,0,0,0-2-2Z"></path>' +
                '<path d="M20,47H4a3.89,3.89,0,0,1-4-4V31a3.89,3.89,0,0,1,4-4H20a3.89,3.89,0,0,1,4,4V43A3.89,3.89,0,0,1,20,47ZM4,29a1.88,1.88,0,0,0-2,2V43a1.88,1.88,0,0,0,2,2H20a1.88,1.88,0,0,0,2-2V31a1.88,1.88,0,0,0-2-2Z"></path>' +
                '<path d="M21,1C16.12,1,12.31,2.42,9.7,5.23,5.75,9.47,6,15.32,6,16H1l6,6,6-6H8s0-.05,0-.07-.34-5.59,3.18-9.35C13.4,4.2,16.7,3,21,3a1,1,0,0,0,0-2Z"></path>' +
                '<path d="M30,46c4.88,0,8.69-1.42,11.3-4.23C45.25,37.53,45,31.68,45,31h5l-6-6-6,6h5s0,.05,0,.07.34,5.59-3.18,9.35C37.6,42.8,34.3,44,30,44a1,1,0,0,0,0,2Z"></path>' +
                '</svg>',
            trash: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 34" data-act="remove">' +
                '<polygon points="22 2 22 1 19 1 19 0 13 0 13 1 10 1 10 2 0 2 0 4 32 4 32 2 22 2"></polygon>' +
                '<path d="M3,7V33H4v1H28V33h1V7ZM5,32V9h6V32Zm8,0V9h6V32Zm14,0H21V9h6Z"></path>' +
                '</svg>',
            toggle: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 8">' +
                '<path d="M8,8a1,1,0,0,1-.65-.24l-7-6A1,1,0,1,1,1.65.24L8,5.68,14.35.24a1,1,0,1,1,1.3,1.52l-7,6A1,1,0,0,1,8,8Z" transform="translate(0 0)"></path>' +
                '<path d="M3,7V33H4v1H28V33h1V7ZM5,32V9h6V32Zm8,0V9h6V32Zm14,0H21V9h6Z"></path>' +
                '</svg>',

            // right
            rFolder: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 41" data-act="priority">' +
                '<path d="M0,10l48,0V38a2.94,2.94,0,0,1-3,3H3a2.94,2.94,0,0,1-3-3Z" transform="translate(0)" />' +
                '<path d="M0,10V3A2.94,2.94,0,0,1,3,0H15a2.94,2.94,0,0,1,3,3V4H45a2.94,2.94,0,0,1,3,3v3Z" transform="translate(0)" opacity="0.5" />' +
                '</svg>',
            rClose: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" data-act="remove">' +
                '<path d="M9.71,9,17.85.85A.47.47,0,0,0,18,.5a.47.47,0,0,0-.15-.35.48.48,0,0,0-.7,0L9,8.29.85.15a.48.48,0,0,0-.7,0,.48.48,0,0,0,0,.7L8.29,9,.15,17.15a.48.48,0,0,0,0,.7.48.48,0,0,0,.7,0L9,9.71l8.15,8.14a.48.48,0,0,0,.7,0,.48.48,0,0,0,0-.7Z" />' +
                '</svg>'
        };
        this.confirm = {
            removeGroup: null
        };

        this.inProcess = false;
        this.photosData = {};
        this.dragPhotoId = 0;
        this.dropGroupId = 0;
        this.dropDetected = false;
        this.selectedGroupId = 0;

        this.registerConfirm();
    }

    bind() {
        this.userIdControl.addEventListener('change', this.handlerUserIdControlChange.bind(this));
        this.pointIdControl.addEventListener('change', this.handlerPointIdControlChange.bind(this));
        this.buttonSessionBlockToogle.addEventListener('click', this.handlerButtonSessionBlockToggleClick.bind(this));
        this.buttonGoToProcess.addEventListener('click', this.handlerButtonGoToProcessClick.bind(this));

        this.buttonsChangeViewType.forEach(element => {
            element.addEventListener('click', this.handlerButtonChangeViewTypeClick.bind(this));
        });

        this.bindGroupDrop(this.rightSideNewFolder);
    }

    bindAfterLoadStreamContent() {
        this.wrapperUl.querySelectorAll('.new').forEach(element => {
            element.classList.remove('new');
            element.addEventListener('click', this.handlerPhotoClick.bind(this));
            element.addEventListener('dragstart', this.handlerPhotoDragStart.bind(this));
            element.addEventListener('dragend', this.handlerPhotoDragEnd.bind(this));
        });

        this.wrapperUl.querySelectorAll('.title_remove_button').forEach(element => {
            element.querySelector('svg[data-act="remove"]').addEventListener('click', this.handlerPhotoMultiRemoveButtonClick.bind(this));
        });
    }

    bindAfterLoadGroups() {
        this.rightWrapper.querySelectorAll('.right_folder').forEach(element => {
            element.querySelector('.img_holder').addEventListener('click', this.handlerGroupImageClick.bind(this));
            element.querySelector('input[type="text"]').addEventListener('change', this.handlerGroupNameChange.bind(this));
            element.querySelector('svg[data-act="priority"]').addEventListener('click', this.handlerGroupPriorityButtonClick.bind(this));
            element.querySelector('svg[data-act="remove"]').addEventListener('click', this.handlerGroupRemoveButtonClick.bind(this));
            this.bindGroupDrop(element);
        });
    }

    bindGroupDrop(element) {
        element.addEventListener('drop', this.handlerGroupDrop.bind(this));
        element.addEventListener('dragover', this.handlerFolderDragOver.bind(this));
        element.addEventListener('dragenter', this.handlerFolderDragEnter.bind(this));
        element.addEventListener('dragleave', this.handlerGroupDragLeave.bind(this));
    }

    init() {
        this.bind();
        this.streamPhoto();

        this.timer = setInterval(() => {
            if (this.wrapperUl) {
                this.appendStreamPhoto();
                this.loadGroups();
            } else {
                this.streamPhoto();
            }
        }, 30000);
    }

    handlerButtonGoToProcessClick(e) {
        e.preventDefault();
        alertify.warning('Ох! Извините, но я ещё пока так не умею :(')
    }

    handlerButtonSessionBlockToggleClick(e) {
        e.preventDefault();
        this.changeSessionBlockToggle(!e.currentTarget.closest('.roll_up').classList.contains('active'));
    }

    handlerButtonChangeViewTypeClick(e) {
        e.preventDefault();
        this.changeViewType(e.currentTarget.dataset.value);
    }

    handlerPhotoMultiRemoveButtonClick(e) {
        e.preventDefault();
        this.removePhotosSessionByElement(e.currentTarget.closest('li'));
    }

    handlerGroupNameChange(e) {
        e.preventDefault();

        this.renameGroup(
            Number(e.currentTarget.closest('.right_folder').dataset.groupId),
            String(e.currentTarget.value).trim()
        );
    }

    handlerGroupRemoveButtonClick(e) {
        e.preventDefault();
        this.removeGroup(Number(e.currentTarget.closest('.right_folder').dataset.groupId))
    }

    handlerGroupPriorityButtonClick(e) {
        e.preventDefault();
        const parent = e.currentTarget.closest('.right_folder');

        this.priorityGroup(
            Number(parent.dataset.groupId),
            !parent.classList.contains('priority')
        );
    }

    handlerGroupImageClick(e) {
        e.preventDefault();
        const parent = e.currentTarget.closest('.right_folder');

        this.selectedGroup(
            Number(parent.dataset.groupId),
            !parent.classList.contains('active')
        );
    }

    handlerPhotoDragStart(e) {
        const target = e.currentTarget;
        const parent = target.closest('.img_with_buttons');

        e.dataTransfer.effectAllowed = 'move';
        e.dataTransfer.setData('text/html', target.closest('.img_with_buttons').innerHTML);

        this.dragPhotoId = Number(parent.dataset.photoId);
        this.dropGroupId = 0;
        this.dropDetected = false;
    }

    handlerPhotoDragEnd() {
        this.afterDragAndDropPhoto();
    }

    handlerFolderDragEnter(e) {
        const target = e.currentTarget;
        target.classList.add('drop');
    }

    handlerFolderDragOver(e) {
        e.preventDefault();
        const target = e.currentTarget;
        target.classList.add('drop');
    }

    handlerGroupDragLeave(e) {
        const target = e.currentTarget;
        target.classList.remove('drop');
    }

    handlerGroupDrop(e) {
        e.stopPropagation();
        const folder = e.currentTarget.closest('.itemFolder');
        this.dropGroupId = Number(folder.dataset.groupId);
        this.dropDetected = true;
        return false;
    }

    handlerUserIdControlChange() {
        this.streamPhoto();
    }

    handlerPointIdControlChange() {
        this.streamPhoto();
    }

    handlerPhotoClick(e) {
        const parent = e.target.closest('.img_with_buttons');
        const photoId = Number(parent.dataset.photoId);
        switch (e.target.dataset.act) {
            case 'remove':
                this.removePhotos([photoId]);
                break;
            case 'folder':
                break;
            case 'options':
                break;
            default:
                this.selectPhoto(parent);
                break;
        }

        console.log('CLK', photoId, e.target.dataset.act);
    }

    changeViewType(viewType) {
        this.photoTypeView.value = viewType;
        this.requestSettingChangeViewType()
            .catch((e) => {
                alertify.error('Что-то пошло не так :(');
                console.error(e)
            });

        this.buttonsChangeViewType.forEach(element => {
            const parent = element.parentNode;
            if (element.dataset.value === viewType) {
                parent.classList.add('active');
            } else {
                parent.classList.remove('active');
            }
        })

        if (!this.wrapperUl) {
            return;
        }

        if (viewType === 'small') {
            this.wrapperUl.classList.add('small_photos')
        } else {
            this.wrapperUl.classList.remove('small_photos')
        }

        this.wrapperUl.querySelectorAll('li').forEach(li => {
            li.querySelectorAll('.img_with_buttons').forEach(element => {
                const image = element.querySelector('.img_holder').querySelector('img');
                image.src = image.dataset[viewType];
            });
        });
    }

    changeSessionBlockToggle(value) {
        const parent = this.buttonSessionBlockToogle.closest('.roll_up');

        if (value) {
            parent.classList.add('active');
        } else {
            parent.classList.remove('active');
        }

        this.photoSessionBlockToggle.value = Number(value);
        this.requestSettingSessionBlockToggle()
            .catch((e) => {
                alertify.error('Что-то пошло не так :(');
                console.error(e)
            });

        if (!this.wrapperUl) {
            return;
        }

        this.wrapperUl.querySelectorAll('li').forEach(li => {
            li.querySelector('input[type="checkbox"]').checked = !value;
        });
    }

    afterDragAndDropPhoto() {
        console.log('DRAG-AFTER', this.dragPhotoId, this.dropGroupId, this.photosData);
        this.rightSide.querySelectorAll('div[data-drop-group="1"]').forEach(element => {
            element.classList.remove('drop');
        });

        this.selectPhotoId(this.dragPhotoId);

        if (this.dropDetected) {
            this.photoAddToGroup();
        }
    }

    streamPhoto() {
        if (this.inProcess) {
            return;
        }
        this.inProcess = true;

        this.requestStreamPhoto()
            .then(json => {
                if (json.success) {
                    if (json.data.sessions) {
                        this.wrapper.innerHTML = this.buildStreamPhotoHtml(json.data.sessions);
                        this.lastPhotoIdControl.value = json.data.lastPhotoId;
                        this.wrapperUl = this.wrapper.querySelector('ul');
                        this.bindAfterLoadStreamContent();
                        alertify.success(json.data.countLabel);
                    } else {
                        this.wrapperUl = null;
                        this.wrapper.innerHTML = json.data.emptyMessage;
                        this.lastPhotoIdControl.value = '0';
                    }
                } else {
                    alertify.error(json.errorMessage);
                    console.error(json)
                }
            })
            .catch((e) => {
                alertify.error('Что-то пошло не так :(');
                console.error(e)
            })
            .finally(() => {
                this.inProcess = false;
                this.loadGroups();
            });
    }

    appendStreamPhoto() {
        if (this.inProcess) {
            return;
        }
        this.inProcess = true;

        this.requestStreamPhoto()
            .then(json => {
                if (json.success) {
                    if (json.data.sessions) {
                        let html = '';
                        json.data.sessions.forEach((session, idx) => {
                            html += this.buildSteamPhotoSessionHtml(session, idx);
                        });
                        this.wrapperUl.insertAdjacentHTML('afterbegin', html);
                        this.lastPhotoIdControl.value = json.data.lastPhotoId;
                        this.bindAfterLoadStreamContent();
                        alertify.success(json.data.countLabel);
                    }
                } else {
                    alertify.error(json.errorMessage);
                    console.error(json)
                }
            })
            .catch((e) => {
                alertify.error('Что-то пошло не так :(');
                console.error(e)
            })
            .finally(() => {
                this.inProcess = false;
            });
    }

    buildStreamPhotoHtml(sessions) {
        let html = `<ul class="accordion${this.photoTypeView.value === 'small' ? ' small_photos' : ''}">`;
        sessions.forEach((session, idx) => {
            html += this.buildSteamPhotoSessionHtml(session, idx);
        });
        html += '</ul>';
        return html;
    }

    buildSteamPhotoSessionHtml(session, idx) {
        console.log('A', this.photoSessionBlockToggle.value);
        let html = '<li class="folder">' +
            '<input type="checkbox"' + (Number(this.photoSessionBlockToggle.value) === 0 ? ' checked="checked"' : '') + '>' +
            `<div class="ico trash_can title_remove_button">${this.iconsSVG.trash}</div>` +
            `<div class="ico show_hide">${this.iconsSVG.toggle}</div>` +
            `<div class="title">` +
            `<span>Сессия: ${session.sessionName}</span>` +
            `<span>${session.date}</span><span>${session.time}</span>` +
            `<span data-ctr="counter">(${session.photoCountLabel})</span>` +
            '</div>' +
            '<div class="content">';

        session.items.forEach(item => {
            html += `<div class="img_with_buttons new" data-photo-id="${item.id}" draggable="true">` +
                '<div class="img_holder">' +
                `<img src="${this.photoTypeView.value === 'small' ? item.images[1] : item.images[0]}" ` +
                `alt="${item.name}" data-big="${item.images[0]}" ` +
                `data-small="${item.images[1]}">` +
                '</div>' +
                '<div class="buttons_holder">' +
                '<div>' +
                `<div class="ico treatment">${this.iconsSVG.opt}</div>` +
                `<div class="ico before_after">${this.iconsSVG.move}</div>` +
                `<div class="ico folder_open">${this.iconsSVG.folder}</div>` +
                '</div>' +
                `<div class="ico trash_can">${this.iconsSVG.trash}</div>` +
                '</div>' +
                '</div>'
        });

        html += '</div>' +
            '</li>';

        return html;
    }

    buildGroupsHtml(groups) {
        let html = '';
        this.selectedGroupId = 0;

        groups.forEach(group => {
            if (group.isSelected) {
                this.selectedGroupId = group.id;
            }
            html +=
                `<div class="right_folder itemFolder${group.isPriority ? ' priority' : ''}${group.isSelected ? ' active' : ''}" data-drop-group="1" data-group-id="${group.id}">` +
                '<div class="img_holder">' +
                (group.photo ? `<img src="${group.photo}" alt=""/>` : '<div class="move_to_folder"><span class="big">+</span><span>Переместить</span><span>в папку</span></div>') +
                '</div>' +
                '<div class="data_holder">' +
                '<div class="form_and_ico">' +
                `<input type="text" value="${group.name}" placeholder="Имя папки"/>` +
                '<div class="ico folder">' +
                this.iconsSVG.rFolder +
                '</div>' +
                '</div>' +
                '<div class="info">' +
                `<div class="billet yellow${group.notProcessed ? ' active' : ''}">Не обработано: <span>${group.notProcessed}</span></div>` +
                `<div class="billet red${group.processing ? ' active' : ''}">На обработке: <span>${group.processing}</span></div>` +
                '</div>' +
                '<div class="info">' +
                `<div class="billet blue${group.processed ? ' active' : ''}">Обработано: <span>${group.processed}</span></div>` +
                `<div class="billet green${group.marked ? ' active' : ''}">Выбрано: <span>${group.marked}</span></div>` +
                `<div class="billet all">Всего: <span>${group.count}</span></div>` +
                `<div class="ico del">${this.iconsSVG.rClose}</div>` +
                '</div>' +
                '</div>' +
                '</div>';
        });

        this.rightWrapper.innerHTML = html;
    }

    photoAddToGroup() {
        if (this.inProcess) {
            return;
        }
        this.inProcess = true;

        this.requestPhotosAddToGroup()
            .then(json => {
                if (json.success) {
                    this.inProcess = false;
                    this.photosData = {};
                    this.refreshAllContents();
                } else {
                    alertify.error(json.errorMessage);
                    console.error(json)
                }
            })
            .catch((e) => {
                alertify.error('Что-то пошло не так :(');
                console.error(e)
            })
            .finally(() => {
                this.photosData = {};
                this.inProcess = false;
            });
    }

    removePhotos(photoIds) {
        this.requestRemovePhotos(photoIds)
            .then(json => {
                if (json.success) {
                    alertify.success(
                        photoIds.length > 1 ?
                            window.locale.pp.photo.remove.successMulti :
                            window.locale.pp.photo.remove.success
                    );

                    const li = this.wrapperUl
                        .querySelector(`.img_with_buttons[data-photo-id="${photoIds[0]}"]`)
                        .closest('li');

                    photoIds.forEach(photoId => {
                        this.wrapperUl
                            .querySelectorAll(`.img_with_buttons[data-photo-id="${photoId}"]`)
                            .forEach(element => {
                                element.remove();
                            });
                    });

                    const newCounter = li.querySelectorAll('.img_with_buttons').length;
                    li.querySelector('.title').querySelector('span[data-ctr="counter"]').innerHTML = `${newCounter} фото`;
                } else {
                    alertify.error(json.errorMessage);
                    console.error(json)
                }
            })
            .catch((e) => {
                alertify.error('Что-то пошло не так :(');
                console.error(e)
            })
            .finally(() => {
                this.inProcess = false;
            });
    }

    removePhotosSessionByElement(li) {
        const photoIds = [];
        li.querySelectorAll('.img_with_buttons').forEach(element => {
            photoIds.push(Number(element.dataset.photoId));
        });
        if (!photoIds.length) {
            li.remove();
            alertify.success(window.locale.pp.photo.remove.successSession);
            return;
        }

        this.requestRemovePhotos(photoIds)
            .then(json => {
                if (json.success) {
                    alertify.success(window.locale.pp.photo.remove.successSession);
                    li.remove();
                    this.refreshAllContents();
                } else {
                    alertify.error(json.errorMessage);
                    console.error(json)
                }
            })
            .catch((e) => {
                alertify.error('Что-то пошло не так :(');
                console.error(e)
            })
            .finally(() => {
                this.inProcess = false;
            });
    }

    loadGroups() {
        if (this.inProcess) {
            return;
        }
        this.inProcess = true;

        this.requestLoadGroups()
            .then(json => {
                if (json.success) {
                    this.buildGroupsHtml(json.data);
                    this.bindAfterLoadGroups();
                } else {
                    alertify.error(json.errorMessage);
                    console.error(json)
                }
            })
            .catch((e) => {
                alertify.error('Что-то пошло не так :(');
                console.error(e)
            })
            .finally(() => {
                this.inProcess = false;
            });
    }

    removeGroup(groupId) {
        if (this.inProcess) {
            return;
        }

        this.removeGroupId = groupId;
        alertify.removeGroup().show();
    }

    renameGroup(groupId, name) {
        this.requestRenameGroup(groupId, name)
            .then(json => {
                if (json.success) {
                    alertify.success(window.locale.pp.group.rename.success);
                } else {
                    alertify.error(json.errorMessage);
                    console.error(json)
                }
            })
            .catch((e) => {
                alertify.error('Что-то пошло не так :(');
                console.error(e)
            })
            .finally(() => {
                this.inProcess = false;
            });
    }

    priorityGroup(groupId, state) {
        if (this.inProcess) {
            return;
        }
        this.inProcess = true;

        this.requestAsPriorityGroup(groupId, state)
            .then(json => {
                if (json.success) {
                    alertify.success(state ?
                        window.locale.pp.group.setPriority.set :
                        window.locale.pp.group.setPriority.unset
                    );
                    this.inProcess = false;
                    this.loadGroups();
                } else {
                    alertify.error(json.errorMessage);
                    console.error(json)
                }
            })
            .catch((e) => {
                alertify.error('Что-то пошло не так :(');
                console.error(e)
            })
            .finally(() => {
                this.inProcess = false;
            });
    }

    selectedGroup(groupId, state) {
        if (this.inProcess) {
            return;
        }
        this.inProcess = true;

        this.requestSelectedGroup(groupId, state)
            .then(json => {
                if (json.success) {
                    alertify.success(state ?
                        window.locale.pp.group.selected.set :
                        window.locale.pp.group.selected.unset
                    );
                    this.inProcess = false;
                    this.loadGroups();
                } else {
                    alertify.error(json.errorMessage);
                    console.error(json)
                }
            })
            .catch((e) => {
                alertify.error('Что-то пошло не так :(');
                console.error(e)
            })
            .finally(() => {
                this.inProcess = false;
            });
    }

    refreshAllContents() {
        this.lastPhotoIdControl.value = '0';
        this.streamPhoto();
    }

    selectPhoto(photo) {
        const photoId = Number(photo.dataset.photoId);
        if (!this.photosData[photoId]) {
            this.photosData[photoId] = {
                selected: true,
                bitOptions: 0
            }
            photo.classList.add('active');
        } else {
            this.photosData[photoId].selected = false;
            photo.classList.remove('active');
        }
    }

    selectPhotoId(photoId) {
        this.selectPhoto(this.wrapperUl.querySelector(`.img_with_buttons[data-photo-id="${photoId}"]`));
    }

    registerConfirm() {
        const self = this;
        this.confirm.removeGroup = alertify.dialog('removeGroup', () => {
            return {
                setup: function () {
                    return {
                        buttons: [
                            {
                                text: window.locale.pp.confirm['group.remove'].btnOk,
                                className: 'red',
                                scope: 'default',
                                invokeOnClose: false,
                                key: 13,
                            },
                            {
                                text: window.locale.pp.confirm['group.remove'].btnCancel,
                                className: 'grey',
                                invokeOnClose: true,
                                cancel: true,
                                key: 27,
                            }
                        ],
                        options: {
                            resizable: false,
                            maximizable: false
                        },
                        transition: 'fade'
                    };
                },
                callback: (closeEvent) => {
                    if (closeEvent.button.key === 13) {
                        self.requestRemoveGroup()
                            .then(json => {
                                if (json.success) {
                                    alertify.success(window.locale.pp.group.remove.success);
                                    this.inProcess = false;
                                    this.refreshAllContents();
                                } else {
                                    alertify.error(json.errorMessage);
                                    console.error(json)
                                }
                            })
                            .catch((e) => {
                                alertify.error('Что-то пошло не так :(');
                                console.error(e)
                            })
                            .finally(() => {
                                this.inProcess = false;
                            });
                    }
                },
                build: function () {
                    this.elements.root.classList.add('pop_up');
                    this.elements.root.classList.add('pop_up_confirm');

                    this.elements.footer.querySelector('.ajs-primary').classList.add('form-group');
                    this.elements.footer.querySelector('.ajs-primary').classList.add('form-buttons');

                    this.setHeader(window.locale.pp.confirm['group.remove'].title);
                    this.setContent(`${window.locale.pp.confirm['group.remove'].body}`);
                }
            }
        });
    }

    async requestRemoveGroup() {
        const response = await fetch(`/${langCode}/group/${this.removeGroupId}/remove`, {
            method: 'POST'
        });
        return await response.json();
    }

    async requestAsPriorityGroup(groupId, state) {
        const response = await fetch(`/${langCode}/group/${groupId}/as-priority/${Number(state)}`, {
            method: 'POST'
        });
        return await response.json();
    }

    async requestSelectedGroup(groupId, state) {
        const response = await fetch(`/${langCode}/group/${groupId}/selected/${Number(state)}`, {
            method: 'POST'
        });
        return await response.json();
    }

    async requestRenameGroup(groupId, name) {
        const formData = new FormData();
        formData.append('renameGroupForm[name]', name);

        const response = await fetch(`/${langCode}/group/${groupId}/rename`, {
            method: 'POST',
            body: formData
        });
        return await response.json();
    }

    async requestLoadGroups() {
        const formData = new FormData();
        formData.append('loadGroupsForm[point]', this.pointIdControl.value);

        const response = await fetch(`/${langCode}/groups`, {
            method: 'POST',
            body: formData
        });
        return await response.json();
    }

    async requestStreamPhoto() {
        const formData = new FormData();
        formData.append('photoStreamForm[point]', this.pointIdControl.value);
        formData.append('photoStreamForm[user]', this.userIdControl.value);
        formData.append('photoStreamForm[lastPhotoId]', this.lastPhotoIdControl.value);

        const response = await fetch(`/${langCode}/photo/stream`, {
            method: 'POST',
            body: formData
        });
        return await response.json();
    }

    async requestPhotosAddToGroup() {
        const photoIds = [];

        Object.keys(this.photosData).forEach(photoId => {
            if (this.photosData[photoId].selected) {
                photoIds.push(photoId);
            }
        });

        const formData = new FormData();
        formData.append('photoAddToGroup[point]', this.pointIdControl.value);
        formData.append('photoAddToGroup[user]', this.userIdControl.value);
        formData.append('photoAddToGroup[group]', this.dropGroupId ? this.dropGroupId : '');
        formData.append('photoAddToGroup[photoIds]', photoIds.join(','));

        const response = await fetch(`/${langCode}/group/add/photos`, {
            method: 'POST',
            body: formData
        });
        return await response.json();
    }

    async requestRemovePhotos(photoIds) {
        const formData = new FormData();
        formData.append('removePhotosForm[point]', this.pointIdControl.value);
        formData.append('removePhotosForm[photoIds]', photoIds.join(','));

        const response = await fetch(`/${langCode}/point/${this.pointIdControl.value}/photos/removes`, {
            method: 'POST',
            body: formData
        });
        return await response.json();
    }

    async requestSettingChangeViewType() {
        const response = await fetch(`/${langCode}/settings/photo-view-type/${this.photoTypeView.value}`, {
            method: 'POST'
        });
        return await response.json();
    }

    async requestSettingSessionBlockToggle() {
        const response = await fetch(`/${langCode}/settings/photo-session-block-toggle/${this.photoSessionBlockToggle.value}`, {
            method: 'POST'
        });
        return await response.json();
    }
}

export default PrintPhotoPhotoStreamForm